/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import axios from 'axios'

import { Col, Field, Row, Text } from '@fullfabric/alma-mater'
import { utils } from '@fullfabric/public-api'

import { useAppData } from 'shared/contexts/AppData'

import Form from '..'
import { EmailField } from './email'
import PrivacyPolicyField from './policy'

import classNames from 'classnames'
import styles from './styles.module.scss'

const createAccount = async (data, location, { onSuccess, onError }) => {
  const digest = await utils.getPageDigest()

  const requestParams = {
    url: '/api/public/accounts',
    method: 'post',
    headers: { 'Content-Type': 'application/json', 'X-FF-DIGEST': digest },
    data: {
      ...data,
      auto: true,
      redirect_path: location.pathname + location.search
    }
  }

  try {
    await axios(requestParams)
    onSuccess()
  } catch (e) {
    onError(e)
  }
}

const SignUp = ({ data, setData, setStep, privacyPolicy }) => {
  const location = useLocation()
  const { template_type } = useAppData()

  const [errors, setErrors] = useState({
    profile: {}
  })

  useEffect(() => setErrors({ profile: {} }), [data])

  const onSubmit = ({ onError }) => {
    const onSuccess = () => {
      window.location.reload()
    }

    const answeredToPrivacyPolicy =
      !privacyPolicy || data?.policies?.[privacyPolicy.id]?.accepted

    if (!answeredToPrivacyPolicy) {
      setErrors({ ...errors, privacy: true })
      onError()
      return
    }

    createAccount(data, location, {
      onSuccess,
      onError: (error) => {
        setErrors({ profile: error?.response?.data['profiles/profile'] })
        onError()
      }
    })
  }

  const onChange = (id, value) => setData({ ...data, [id]: value })

  return (
    <Form
      onSubmit={onSubmit}
      step='signUp'
      setStep={setStep}
      showModalActions={true}
    >
      <div className={classNames([styles.emailField])}>
        <EmailField data={data} setData={setData} errors={errors.profile} />
      </div>

      <Text type='h2' fontColor='text-base-darkest' marginBottom='more'>
        {template_type === 'application' ? (
          <Trans>Welcome! Let's start your new application.</Trans>
        ) : (
          <Trans>Welcome! Let's start your new registration.</Trans>
        )}
      </Text>

      <Text type='f4' fontColor='text-base-darkest'>
        {template_type === 'application' ? (
          <Trans>
            Please enter the following details so that you can revisit your
            application if you exit it.
          </Trans>
        ) : (
          <Trans>
            Please enter the following details so that you can revisit your
            registration if you exit it.
          </Trans>
        )}
      </Text>

      <Text
        type='f5'
        fontColor='text-base-darker'
        marginTop='default'
        marginBottom='default'
      >
        <Trans>Email address </Trans>
        <Text type='h5' tag='span'>
          {' '}
          {data.email}
        </Text>
      </Text>

      <Row>
        <Col sm={6} xs={12}>
          <Field
            id='first_name'
            type='text'
            label={<Trans>First name</Trans>}
            onChange={onChange}
            onBlur={(id, value) => true}
            error={errors.profile.first_name}
            inputOptions={{ autoFocus: true }}
          ></Field>
        </Col>

        <Col sm={6} xs={12}>
          <Field
            id='last_name'
            type='text'
            label={<Trans>Last name</Trans>}
            onChange={onChange}
            onBlur={(id, value) => true}
            error={errors.profile.last_name}
          ></Field>
        </Col>
      </Row>

      {!!privacyPolicy && (
        <PrivacyPolicyField
          data={data}
          setData={setData}
          errors={errors.privacy}
          privacyPolicy={privacyPolicy}
        />
      )}
    </Form>
  )
}

export default SignUp
